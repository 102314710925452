import React from 'react';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/dj-signup.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village Workshop & Performers Signup</title>
    </PageHead>
  );
}

function WorkshopSignupPage() {
  return (
    <Page className={styles.DjSignup}>
      <section className={styles.pageCenter}>
        <h2 className={styles.smallHeading}>Catalün Workshop & Performers Signup</h2>
        <p className={styles.subtext}>
          <span>Request to collaborate and use our space for your work.</span>
        </p>
        <iframe
          title="Catalun Collab Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdNYHXSs_p7IiwAx75mNO3eSS2-TcBW90rByCSLYCalIPGKPQ/viewform?embedded=true"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe>
      </section>
    </Page>
  );
}

export default WorkshopSignupPage;
